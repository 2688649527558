const getDomain = () => {
  switch (process.env.NEXT_PUBLIC_APP_ENV) {
    case 'staging':
      return 'https://staging.houzit.com';
    case 'prod':
      return 'https://houzit.com';
    default:
      return 'http://localhost:3000';
  }
};

export default getDomain;
