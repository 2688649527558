import { IListing } from '../../shared/models/listing/listing.model';
import { getListingCity } from './getListingCity';
import { getTranslateRoutes } from '../i18n/getTranslateRoutes';
import { DEFAULT_LANG } from '../../shared/const/language.const';

export const getListingUrl = (
  listing: IListing,
  locale = DEFAULT_LANG,
  context = 'listing'
): string => {
  const { id } = listing;
  const route = getTranslateRoutes(locale, context);
  const city = getListingCity(listing);

  const url = `${route}/${city}/${id}/`;

  return url;
};
