import { getAlternateRoutes } from '../../../../lib/i18n/getTranslateRoutes';
import getDomain from '../../../../lib/lang';
import { DEFAULT_LANG } from '../../../const/language.const';

interface IAlternateLinksProps {
  asPath: string;
  locale: string;
  withCanonical?: boolean;
}

export const AlternateLinks = ({
  asPath = `/${DEFAULT_LANG}`,
  locale = DEFAULT_LANG,
  withCanonical = false
}: IAlternateLinksProps) => {
  const domain = getDomain();

  return (
    <>
      <link
        rel="alternate"
        href={`${domain}/es${getAlternateRoutes(locale, 'es', asPath)}`}
        hrefLang="es"
        key="link-alternate-es"
      />
      <link
        rel="alternate"
        href={`${domain}/en${getAlternateRoutes(locale, 'en', asPath)}`}
        hrefLang="en"
        key="link-alternate-en"
      />
      {withCanonical && (
        <link
          rel="canonical"
          href={`${domain}/${locale}${asPath}`}
          key="link-canonical"
        />
      )}
    </>
  );
};
