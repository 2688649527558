// Generated using http://json2ts.com/

import { getListingUrl } from '../../../lib/listing/getListingUrl';
import { DEFAULT_LANG } from '../../const/language.const';

export interface IOwner {
  id: number;
  fullName: string;
  phoneNumber: string;
  email: string;
  comment: string;
  listings: string[];
  created_by: string;
  updated_by: string;
}

export interface IAgent {
  id: number;
  description: string;
  fullName: string;
  phoneNumber: string;
  listings: string[];
  leads: string[];
  email: string;
  created_by: string;
  updated_by: string;
}

export interface IListingFeatures {
  // id: number;
  swimmingPool: boolean;
  gamesRoom: boolean;
  fullyEquippedGym: boolean;
  roofTerrace: boolean;
  ultraModern: boolean;
  airConditioning: boolean;
  airspace: boolean;
  modern: boolean;
  balcony: boolean;
  basement: boolean;
  bathTub: boolean;
  standard: boolean;
  cableTV: boolean;
  centralHeating: boolean;
  detached: boolean;
  dishwasher: boolean;
  energySavingBulbs: boolean;
  fireplace: boolean;
  furnished: boolean;
  unfurnished: boolean;
  garage: boolean;
  garden: boolean;
  greyWaterEfficient: boolean;
  groundFloor: boolean;
  insulation: boolean;
  internet: boolean;
  jacuzzi: boolean;
  lift: boolean;
  openPlan: boolean;
  parkingSpace: boolean;
  partOfRoof: boolean;
  petFriendly: boolean;
  reverseOsmosis: boolean;
  seaView: boolean;
  seaFront: boolean;
  semiDetached: boolean;
  solarPanels: boolean;
  study: boolean;
  terrace: boolean;
  townView: boolean;
  tumbleDryer: boolean;
  utilityBillsIncluded: boolean;
  utilityRoom: boolean;
  valleyView: boolean;
  washingMachine: boolean;
  wheelchair: boolean;
  yard: boolean;
  LCDTV: boolean;
}

export interface IListingAmenities {
  id: number;
  busStop: boolean;
  bank: boolean;
  ATM: boolean;
  pharmacy: boolean;
  beach: boolean;
  lido: boolean;
  supermarket: boolean;
  minimarket: boolean;
  hairSalon: boolean;
  restuarants: boolean;
  nightClubs: boolean;
  bars: boolean;
}

export type ProviderMetadata = any; // TODO

export interface Format {
  ext: string;
  url: string;
  hash: string;
  mime: string;
  name: string;
  path?: any;
  size: number;
  width: number;
  height: number;
}

export interface Formats {
  small?: Format;
  medium?: Format;
  large?: Format;
  thumbnail?: Format;
}

export interface Photo {
  id: number;
  name: string;
  alternativeText: string;
  caption: string;
  width: number;
  height: number;
  formats: Formats;
  hash: string;
  ext: string;
  mime: string;
  size: number;
  url: string;
  previewUrl?: any;
  provider: string;
  provider_metadata?: any;
  created_at: Date;
  updated_at: Date;
}

export interface RootObject {
  photos: Photo[];
}

export interface IPhoto {
  id: number;
  name: string;
  alternativeText: string;
  caption: string;
  width: number;
  height: number;
  formats: Formats;
  hash: string;
  ext: string;
  mime: string;
  size: number;
  url: string;
  previewUrl: string | null;
  provider: string;
  provider_metadata: ProviderMetadata;
  related?: string;
  created_by?: string;
  created_at?: string;
  updated_by?: string;
  updated_at?: string;
}

export type Formats2 = any; // TODO

export type ProviderMetadata2 = any; // TODO

export interface IVideo {
  id: number;
  name: string;
  alternativeText: string;
  caption: string;
  width: number;
  height: number;
  formats: Formats2;
  hash: string;
  ext: string;
  mime: string;
  size: number;
  url: string;
  previewUrl: string;
  provider: string;
  provider_metadata: ProviderMetadata2;
  related: string;
  created_by?: string;
  created_at?: string;
  updated_by?: string;
  updated_at?: string;
}

export interface ILead {
  id: number;
  listing: string;
  prefferedDate: string;
  message: string;
  phone: string;
  email: string;
  agent: string;
  status: string;
  name: string;
  created_by?: string;
  created_at?: string;
  updated_by?: string;
  updated_at?: string;
}

export enum ListingType {
  all = 'all',
  flat = 'flat',
  penthouse = 'penthouse',
  house = 'house',
  duplex = 'duplex',
  studio = 'studio'
}

export interface IListingPlace {
  id: number;
  title: string;
  district: {
    id: number;
    title: string;
  };
}

export interface IListing {
  id: number;
  type: ListingType;
  address: string;
  rentalPrice: number;
  description: string;
  description_en: string;
  area: number;
  bedsCount: number;
  bathsCount: number;
  owner: IOwner | null;
  agent: IAgent | null;
  isForSale: boolean;
  isLongLet: boolean;
  isShortLet: boolean;
  salePrice: number;
  availableFrom: string;
  clientStatus: string;
  longitude: number | null;
  latitude: number | null;
  timeSlotsOnRequest: boolean | null;

  place: IListingPlace | null;

  photos: IPhoto[];
  videos: IVideo[];
  title: string;
  lead: ILead | null;
  leads: ILead[];
  published_at: string;
  yearBuilt: string | null;
  created_by?: string;
  created_at?: string;
  updated_by?: string;
  updated_at?: string;

  // Features used for filtering
  feature_swimmingPool?: boolean;
  feature_furnished?: boolean;
  feature_unfurnished?: boolean;
  feature_utilityRoom?: boolean;
  feature_balcony?: boolean;
  feature_garage?: boolean;
  feature_petFriendly?: boolean;
  feature_garden?: boolean;
  feature_lift?: boolean;
  feature_wheelchair?: boolean;
  feature_airConditioning?: boolean;
}

export class Listing implements IListing {
  address!: string;
  agent!: IAgent | null;
  area!: number;
  availableFrom!: string;
  bathsCount!: number;
  bedsCount!: number;
  clientStatus!: string;
  latitude: number | null = null;
  longitude: number | null = null;
  description!: string;
  description_en!: string;
  id!: number;
  isForSale!: boolean;
  isLongLet!: boolean;
  isShortLet!: boolean;
  lead!: ILead;
  listingAmenities!: IListingAmenities;
  listingFeatures!: IListingFeatures;
  owner!: IOwner | null;
  photos!: IPhoto[];
  published_at!: string;
  rentalPrice!: number;
  salePrice!: number;
  title!: string;
  type!: ListingType;
  videos!: IVideo[];
  leads!: ILead[];
  yearBuilt!: string | null;
  place!: IListingPlace | null;
  timeSlotsOnRequest!: boolean | null;

  constructor(listing: IListing) {
    Object.assign(this, listing);
  }

  static getPhotoBestQuality(photo: IPhoto) {
    return (
      photo?.formats?.large?.url ||
      photo?.formats?.medium?.url ||
      photo?.formats?.small?.url ||
      photo?.url ||
      photo?.formats?.thumbnail?.url
    );
  }

  static getPhotoThumbnail(photo?: IPhoto) {
    return (
      photo?.formats?.thumbnail?.url ||
      photo?.formats?.small?.url ||
      photo?.formats?.medium?.url ||
      photo?.formats?.large?.url ||
      photo?.url
    );
  }

  static getPhotoSrcSet(photo?: IPhoto, isMiniature = false) {
    let srcSet = '';

    if (photo?.formats?.thumbnail) {
      srcSet += `${photo.formats.thumbnail.url} ${photo.formats.thumbnail.width}w,`;
    }

    if (photo?.formats?.small) {
      srcSet += `${photo.formats.small.url} ${photo.formats.small.width}w,`;
    }

    if (photo?.formats?.medium) {
      srcSet += `${photo.formats.medium.url} ${photo.formats.medium.width}w,`;
    }

    if (photo?.formats?.large && !isMiniature) {
      srcSet += `${photo.formats.large.url} ${photo.formats.large.width}w,`;
    }

    return srcSet;
  }

  static getMetaData(listing: IListing, lang = DEFAULT_LANG) {
    const images = listing.photos.map(
      ({ formats, url }) => formats?.small?.url || formats?.medium?.url || url
    );

    let domain;

    if (typeof window !== 'undefined') {
      domain = window.location.origin;
    } else {
      domain = process.env.NEXT_PUBLIC_URL;
    }

    const url = `${domain || 'https://houzit.com'}/${lang}${getListingUrl(
      listing,
      lang
    )}`;

    const description =
      lang === 'es' ? listing.description : listing.description_en;

    return {
      url,
      type: 'article',
      title: listing.title,
      description,
      image: images[0],
      images
    };
  }

  static getPlaceString(place: IListingPlace) {
    let str = place.title;
    if (place.district) {
      str = `${str}, ${place.district.title}`;
    }
    return str;
  }

  static getPlaceSeoString(place: IListingPlace | null, placePrefix: string) {
    const { title = null, district } = place || {};

    if (!title) {
      return '';
    }

    let str = `${placePrefix} ${title}`;

    if (district) {
      str += ` - ${district.title}`;
    }

    return str;
  }

  static getSeoTitle(
    t: (key: string) => string,
    listing?: IListing,
    language?: string
  ): string {
    if (!listing) {
      return 'Houzit';
    }

    const { place, type, bedsCount } = listing || {};
    const placeString = this.getPlaceSeoString(place, t('in'));
    const hasBeds = listing.bedsCount > 0;
    let title = '';

    if (!hasBeds) {
      title = t(type);
    } else if (language === 'en') {
      title = `${bedsCount} ${t('bed')} ${t(type).toLowerCase()}`;
    } else {
      title = `${t(type)} de ${bedsCount} ${t(
        listing.bedsCount > 1 ? 'bedrooms' : 'bedroom'
      ).toLowerCase()}`;
    }

    return `${title}${placeString ? ` ${placeString}` : ''} - Houzit`;
  }

  static getGeneratedTitle(
    t: (key: string) => string,
    translationKey: string,
    listing?: IListing,
    language?: string
  ) {
    if (!listing) {
      return '';
    }
    const hasBeds = listing.bedsCount > 0;
    const bedsTranslation =
      language === 'en'
        ? 'bed'
        : listing.bedsCount > 1
        ? 'bedrooms'
        : 'bedroom';
    const bedsContent = hasBeds
      ? `${language === 'es' ? 'de ' : ''}${listing.bedsCount} ${t(
          bedsTranslation
        ).toLowerCase()} `
      : '';

    if (!hasBeds) {
      return `${t(listing.type)}${
        listing.place?.title ? ` ${t('in')} ${listing.place.title}` : ''
      }`;
    }

    if (language === 'es') {
      return `${t(listing.type)} ${bedsContent}${
        listing.place?.title ? ` ${t('in')} ${listing.place.title}` : ''
      }`;
    }

    const listingType = hasBeds
      ? t(listing.type).toLowerCase()
      : t(listing.type);

    return `${bedsContent}${listingType}${
      listing.place?.title ? ` ${t('in')} ${listing.place.title}` : ''
    }${translationKey === 'listingDetail' ? ` ${t('to-rent')}` : ''}`;
  }

  static getDescription(language = DEFAULT_LANG, listing?: IListing) {
    const hasTranslation = listing?.description_en;

    return language === 'en' && hasTranslation
      ? listing?.description_en
      : listing?.description;
  }
}
