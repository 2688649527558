import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'next-i18next';
import { IListing } from '../../../models/listing/listing.model';
import styles from './ListingCardChip.module.css';
import { format as formatDate } from 'date-fns';

import TimerIconSVG from './../../../../public/assets/icons/timer.svg';

export interface IListingCardChipProps {
  listing: IListing;
  className?: string;
}

export const ListingCardChip: React.FC<IListingCardChipProps> = ({
  listing,
  className
}) => {
  const { t } = useTranslation(['common']);
  let chipText = '';
  let isAvailableNow = false;

  if (listing && listing.availableFrom) {
    const now = new Date();
    const availableFromDateTime = new Date(listing.availableFrom);
    isAvailableNow = availableFromDateTime.getTime() <= now.getTime();

    if (isAvailableNow) {
      chipText = t('now');
    } else {
      const formatedDate = formatDate(availableFromDateTime, 'dd. MM. yyyy');
      chipText = `${t('on')}${formatedDate}`;
    }
  }

  return (
    <div className={clsx(styles.container)}>
      <span
        className={clsx(
          styles.chip,
          className,
          isAvailableNow && styles.chipAvailable
        )}
      >
        {chipText}
      </span>
      {listing?.timeSlotsOnRequest && (
        <span
          className={clsx(styles.chip, className, styles.timeSlotsOnRequest)}
        >
          <TimerIconSVG />
          <span>{t('timeslots-on-request')}</span>
        </span>
      )}
    </div>
  );
};
