import React from 'react';
import dynamic from 'next/dynamic';

export interface IRootSpinnerState {
  show: boolean;
}

export interface IRootSpinnerMethods {
  set: (statement: boolean) => any;
}

export const initialState: IRootSpinnerState = {
  show: false
};

export type RootSpinnerContextType = IRootSpinnerState & IRootSpinnerMethods;

export const RootSpinnerContext = React.createContext<RootSpinnerContextType>(
  initialState as RootSpinnerContextType
);

export const useRootSpinner = () => React.useContext(RootSpinnerContext);

const RootSpinnerDynamic = dynamic<any>(
  () => import('./RootSpinner').then(m => m.RootSpinner),
  {
    ssr: false
  }
);

export const RootSpinnerProvider: React.FC = ({ children }) => {
  const [show, set] = React.useState<boolean>(false);
  return (
    <RootSpinnerContext.Provider value={{ show, set }}>
      {children}
      <RootSpinnerDynamic />
    </RootSpinnerContext.Provider>
  );
};
